import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import Scrollspy from "react-scrollspy";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Waypoint } from "react-waypoint";
import {
  mainWhite,
  darkBlue,
  lightBlue,
  mainBlue,
  darkGrey,
  lightGrey,
  transHover,
  PageTitle,
  Subtitle,
  InnerWrapper,
  StyledA,
  screen,
} from "../components/common/variables";
import { movingDash } from "../components/common/animations";
import SignsIcon from "../components/assets/icons/signs-icon.svg";
import StrategyIcon from "../components/assets/icons/strategy-icon.svg";
import OutcomesIcon from "../components/assets/icons/outcomes-icon.svg";
import FeaturesIcon from "../components/assets/icons/features-icon.svg";
import SuccessStoriesIcon from "../components/assets/icons/success-stories-icon.svg";
import PricingIcon from "../components/assets/icons/pricing-icon.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaDownload } from "react-icons/fa";
import MainDashed1 from "../components/assets/dashed-lines/program-main-1.svg";
import MainDashed2 from "../components/assets/dashed-lines/program-main-2.svg";
import MainDashed3 from "../components/assets/dashed-lines/program-main-3.svg";
import OnlineDashed1 from "../components/assets/dashed-lines/program-online-1.svg";
import OnlineDashed2 from "../components/assets/dashed-lines/program-online-2.svg";
import Carousel from "../components/carousel";
import CountSection from "../components/programs-page/count-section";
import Products from "../components/programs-page/product-table";
import IconHeading from "../components/assets/icons/global-adviser-alpha-client-centric-advice-program.svg";

const Wrapper = styled.div`
  padding-top: 0;
  background: ${mainWhite};
  @media ${screen.medium} {
    padding-top: 70px;
  }

  .nav-bar {
    position: sticky;
    top: 0;
    background: ${mainWhite};
    z-index: 3;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
    display: none;
    @media ${screen.medium} {
      display: block;
    }

    &__list {
      display: flex;
      max-width: 830px;
      margin: 0 auto;
      justify-content: space-evenly;
      text-align: center;
    }

    &__links {
      display: block;
      padding-bottom: 20px;
      display: block;
      position: relative;

      &:hover svg {
        #dark .cls-1 {
          stroke: ${mainBlue};
        }

        #light .cls-1 {
          stroke: ${lightBlue};
        }
      }
      &:hover p {
        color: ${lightBlue};
      }
      &:hover li::before {
        opacity: 1;
      }

      li {
        position: relative;
        padding-left: 23px;
        padding-right: 23px;
        &::before {
          content: "";
          position: absolute;
          height: 6px;
          background: ${lightBlue};
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          opacity: 0;
          transition: ${transHover};
        }
        p {
          font-size: 0.95rem;
          color: ${darkGrey};
          padding: 28px 0 20px 0;
          transition: ${transHover};
        }
      }
      svg {
        width: 45px;
        height: 45px;

        #dark-pricing {
          fill: #575757;
        }

        #light-pricing .cls-1 {
          fill: #575757;
        }
      }

      &--hidden {
        position: absolute;
        display: none;
      }
    }

    .is-current {
      svg {
        #dark .cls-1 {
          stroke: ${mainBlue};
        }

        #light .cls-1 {
          stroke: ${lightBlue};
        }
      }
      p {
        color: ${lightBlue};
      }
      li::before {
        opacity: 1;
      }
    }
  }

  .main-description {
    background: ${darkBlue};
    padding-top: 30px;
    @media ${screen.small} {
      padding-top: 45px;
    }
    @media ${screen.medium} {
      padding-top: 90px;
    }

    .inner-wrapper {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0 35px;
      flex-wrap: wrap;
      @media ${screen.small} {
        flex-wrap: nowrap;
      }
      @media ${screen.medium} {
        padding: 0 60px;
      }
      @media ${screen.xLarge} {
        padding: 0;
      }
    }

    .title {
      font-weight: 900;
      font-size: 2.6rem;
      @media ${screen.xLarge} {
        font-size: 4rem;
      }
    }

    .vector-heading {
      max-width: 220px;
      margin: 0 0 -11px 0;
      @media ${screen.small} {
        margin: 0 0 34px 0;
        max-width: 280px;
      }
      @media ${screen.large} {
        margin: 0 0 34px 0;
        max-width: 370px;
      }
    }

    &__content {
      max-width: 640px;
      @media ${screen.medium} {
        max-width: 518px;
      }
      @media ${screen.xLarge} {
        margin-left: calc(50% - 650px);
      }

      p {
        color: ${mainWhite};
        font-size: 1rem;
        margin-top: 20px;
        @media ${screen.small} {
          font-size: 1.3rem;
        }
        @media ${screen.xLarge} {
          font-size: 1.5rem;
        }
      }
    }

    &__img {
      max-width: 820px;
      width: 100%;
      position: relative;
      display: none;
      @media ${screen.medium} {
        display: block;
        max-width: 620px;
        margin-left: 35px;
      }
      @media ${screen.large} {
        max-width: 700px;
      }
      @media ${screen.xLarge} {
        max-width: 800px;
        margin-left: 0;
      }
      @media ${screen.xXLarge} {
        max-width: 940px;
      }

      img {
        z-index: 0 !important;
      }
    }
  }

  .signs-container {
    background: ${darkBlue};
    padding-top: 30px;
    @media ${screen.small} {
      padding-top: 60px;
    }
    @media ${screen.medium} {
      padding-top: 160px;
    }
    @media ${screen.large} {
      padding-top: 180px;
    }

    .subtitle {
      margin-bottom: 15px;
      @media ${screen.small} {
        margin-bottom: 30px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        @media ${screen.small} {
          max-width: 640px;
        }
        @media ${screen.medium} {
          max-width: 305px;
        }
        @media ${screen.xLarge} {
          max-width: 380px;
        }
        h3 {
          color: ${lightBlue};
          font-size: 1rem;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
        }
        p {
          color: ${mainWhite};
          margin-top: 2px;
          margin-bottom: 12px;
          font-size: 1rem;
          @media ${screen.small} {
            font-size: 1.1rem;
            margin-top: 10px;
            margin-bottom: 40px;
          }
        }
      }
    }
    .dashed-line-container {
      position: relative;
      margin-right: 0;
      padding-bottom: 30px;
      padding-top: 30px;
      @media ${screen.large} {
        margin-right: 30px;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 110px;
        padding-top: 0;
      }

      h3 {
        color: ${mainWhite};
        font-size: 1rem;
        margin-top: 15px;
        margin-right: 0;
        @media ${screen.small} {
          font-size: 1.3rem;
        }
        @media ${screen.large} {
          margin-right: 91px;
          margin-top: 30px;
          font-size: 1.5rem;
        }
      }

      &__top-line {
        width: 85px;
        position: absolute;
        top: -60px;
        right: 145px;
        display: none;
        @media ${screen.large} {
          display: block;
        }
        .dashed {
          stroke-dasharray: 12 16;
        }
        .path {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: ${movingDash} 5s ease-out forwards;
        }
      }
      &__left-line {
        width: 210px;
        position: absolute;
        top: 48px;
        right: 670px;
        display: none;
        @media ${screen.large} {
          display: block;
        }
        .dashed {
          stroke-dasharray: 12 16;
        }
        .path {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: ${movingDash} 5s 0.4s ease-out forwards;
        }
      }
    }
  }

  .secondary-description {
    background: ${mainWhite};
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    @media ${screen.small} {
      padding-top: 45px;
      padding-bottom: 45px;
    }
    @media ${screen.large} {
      padding-top: 90px;
      padding-bottom: 10px;
    }

    .inner-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__content {
      position: relative;
      z-index: 2;
      @media ${screen.medium} {
        max-width: 580px;
      }

      h4 {
        color: ${darkBlue};
        font-size: 1rem;
        margin-bottom: 12px;
        width: 80%;
        font-weight: 600;
        @media ${screen.small} {
          font-size: 1.1rem;
          margin-bottom: 20px;
        }
        strong {
          font-weight: 900;
          font-size: 1.1rem;
        }
      }

      ul {
        margin-top: -6px;
        @media ${screen.small} {
          margin-top: -10px;
        }
      }
      li {
        color: ${darkBlue};
        font-size: 1rem;
        line-height: 1.4;
        background: ${lightGrey};
        margin-bottom: 15px;
        padding: 15px;
        @media ${screen.small} {
          padding: 30px;
          font-size: 1.25rem;
        }
      }
    }

    &__img {
      max-width: 462px;
      width: 100%;
      display: none;
      @media ${screen.medium} {
        display: block;
        max-width: 362px;
        margin-left: 30px;
      }
      @media ${screen.medium} {
        max-width: 442px;
        margin-left: 0;
      }
      @media ${screen.large} {
        max-width: 462px;
      }
    }

    &__dashed-bottom {
      box-sizing: content-box;
      width: 60px;
      margin: 15px auto;
      position: absolute;
      z-index: 1;
      left: 49%;
      bottom: -30px;
      transform: translateX(-80%);
      display: none;
      @media ${screen.large} {
        display: block;
      }
      .dashed {
        stroke-dasharray: 12 16;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: ${movingDash} 7s ease-out forwards;
      }
    }
  }

  .tab-container {
    background: ${mainWhite};
    text-align: center;
    padding-top: 20px;
    @media ${screen.small} {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
    @media ${screen.large} {
      padding-top: 40px;
      padding-left: 0;
      padding-right: 0;
    }
    &__desktop-description {
      color: ${darkBlue};
      font-size: 1rem;
      padding: 0 35px;
      display: none;
      @media ${screen.xSmall} {
        display: block;
      }
      @media ${screen.small} {
        font-size: 1.25rem;
      }
      .bold-text {
        font-weight: 900;
        font-size: 1rem;
        @media ${screen.small} {
          font-size: 1.3rem;
        }
      }
    }

    &__mobile-description {
      color: ${darkBlue};
      font-size: 1.07rem;
      padding: 0 35px;
      text-align: left;
      @media ${screen.xSmall} {
        display: none;
      }
      span {
        font-weight: 900;
        font-size: 1rem;
      }
    }

    &__tabs {
      display: flex;
      justify-content: center;
      padding-top: 58px;
      position: relative;
      @media ${screen.small} {
        padding-top: 40px;
      }

      .bold-text {
        font-weight: 900;
        font-size: 1rem;

        @media ${screen.small} {
          font-size: 1.35rem;
        }
      }
    }

    &__bg-illusion {
      height: 60px;
      width: 500px;
      background: ${lightGrey};
      position: absolute;
      z-index: 1;
      display: none;
      @media ${screen.small} {
        display: block;
      }
    }
  }

  .success-stories-container {
    position: relative;

    &__dashed-top {
      width: 490px;
      position: absolute;
      right: 0;
      top: -85px;
      margin: 0 342px 0 auto;
      display: none;
      @media ${screen.small} {
        display: block;
      }
      .dashed {
        stroke-dasharray: 12 16;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: ${movingDash} 5s ease-out forwards;
      }
    }
  }

  .get-started-container {
    background: ${darkBlue};
    padding-top: 68px;

    &__dashed-top {
      width: 140px;
      position: absolute;
      top: 24px;
      margin: 0 auto;
      display: none;
      @media ${screen.large} {
        display: inline;
      }
      .dashed {
        stroke-dasharray: 12 16;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: ${movingDash} 5s 0.8s ease-out forwards;
      }
    }

    .subtitle {
      line-height: 1.2;
    }

    &__description {
      color: ${mainWhite};
      font-size: 1.15rem;
      font-style: italic;
      margin-top: 10px;
      margin-bottom: 20px;

      @media ${screen.small} {
        font-size: 1.6rem;
        margin-bottom: 36px;
      }
      span {
        font-weight: 900;
      }
      a {
        color: ${lightBlue};
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .bottom-nav {
    background-color: ${mainWhite};
    padding: 25px 35px 27px 35px;
    @media ${screen.small} {
      display: none;
    }
    p {
      color: ${darkBlue};
      font-size: 1.15rem;
      a {
        color: ${lightBlue};
        font-weight: 700;
        display: table;
        border-bottom: 1px solid ${lightBlue};
      }
    }
  }
`;

// =====================================

// =====================================

//********** START ONLINE  **********//
const OnlineWrapper = styled.div`
  background: ${lightBlue};
  padding-top: 30px;
  margin-top: 30px;
  @media ${screen.small} {
    padding-top: 60px;
    margin-top: 45px;
  }
  @media ${screen.small} {
    padding-top: 45px;
    margin-top: 30px;
  }

  @media ${screen.large} {
    padding-top: 90px;
    margin-top: 60px;
  }

  .main-title {
    color: ${darkBlue};
    text-align: center;
    margin-bottom: 40px;
    padding: 0 35px;
    font-weight: 900;
    font-size: 2.6rem;
    @media ${screen.small} {
      margin-bottom: 105px;
    }
    @media ${screen.large} {
      margin-bottom: 120px;
      padding: 0;
    }
    @media ${screen.xLarge} {
      font-size: 4rem;
    }

    &__top {
      margin-bottom: 3px;
      line-height: 1.2;
      display: block;
      @media ${screen.small} {
        margin-bottom: 5px;
        line-height: 1.4;
      }

      color: ${mainWhite};
      font-size: 1.6rem;
      font-weight: 900;
      @media ${screen.medium} {
        font-size: 2rem;
      }
      @media ${screen.large} {
        font-size: 2.6rem;
      }

      .break {
        display: block;
        @media ${screen.small} {
          display: inline;
        }
      }
    }

    &__bottom {
      font-size: 1.25rem;
      color: ${mainWhite};
      font-style: italic;
      font-weight: 600;
      display: block;
      margin-top: 8px;
      @media ${screen.small} {
        font-size: 1.68rem;
        margin-top: 15px;
      }
    }
  }

  .strategy-container {
    .inner-wrapper {
      display: flex;
      justify-content: space-between;
      position: relative;
    }

    .subtitle {
      color: ${darkBlue};
      margin-bottom: 2px;
      @media ${screen.small} {
        margin-bottom: 20px;
      }
    }

    &__img {
      max-width: 462px;
      width: 100%;
      display: none;
      @media ${screen.medium} {
        display: block;
        max-width: 320px;
        margin-left: 30px;
      }
      @media ${screen.medium} {
        max-width: 412px;
        margin-left: 50px;
      }
      @media ${screen.xLarge} {
        max-width: 462px;
        margin-left: 0;
      }
    }

    &__description {
      @media ${screen.medium} {
        max-width: 674px;
      }
      h4 {
        font-size: 1.15rem;
        color: ${mainWhite};
        margin-bottom: 4px;
        font-weight: 600;
        @media ${screen.small} {
          font-size: 1.6rem;
          margin-bottom: 20px;
        }
      }
      p {
        color: ${mainWhite};
        font-size: 1rem;
        @media ${screen.xLarge} {
          font-size: 1.1rem;
        }
      }

      strong {
        color: ${darkBlue};
        display: block;
        margin-bottom: -30px;
      }
    }

    &__list {
      margin-top: 36px;
      max-width: 674px;
      display: none;
      @media ${screen.small} {
        display: block;
      }
      li {
        margin-bottom: 36px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      p {
        color: ${mainWhite};
        font-size: 1rem;
        @media ${screen.xLarge} {
          font-size: 1.1rem;
        }
      }
      h4 {
        color: ${darkBlue};
        font-size: 1.1rem;
        font-weight: 700;
        margin-bottom: 5px;
      }
    }

    &__mobile-accordion {
      max-width: 674px;
      padding: 8px 0;
      margin-top: 15px;
      @media ${screen.small} {
        display: none;
      }
      li {
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      p {
        color: ${mainWhite};
        font-size: 1rem;
        margin-top: 2px;
        margin-bottom: 12px;
      }
      h4 {
        color: ${darkBlue};
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }

  .features-container {
    padding-top: 45px;
    position: relative;
    @media ${screen.small} {
      padding-top: 60px;
    }
    @media ${screen.medium} {
      padding-top: 160px;
    }
    @media ${screen.large} {
      padding-top: 180px;
    }

    &__dashed-top {
      width: 135px;
      position: absolute;
      top: 20px;
      display: none;
      @media ${screen.large} {
        display: block;
      }
      .dashed {
        stroke-dasharray: 12 16;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: ${movingDash} 6s ease-out forwards;
      }
    }

    .subtitle {
      color: ${darkBlue};
      margin-bottom: 2px;
      @media ${screen.small} {
        margin-bottom: 20px;
      }
    }

    &__subcontainer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media ${screen.medium} {
        flex-wrap: nowrap;
      }
    }

    &__content {
      @media ${screen.medium} {
        max-width: 644px;
      }
      h4 {
        font-size: 1.15rem;
        color: ${mainWhite};
        margin-bottom: 4px;
        font-weight: 600;
        @media ${screen.small} {
          font-size: 1.6rem;
          margin-bottom: 20px;
        }
      }
      p {
        color: ${mainWhite};
        font-size: 1rem;
        margin-bottom: 10px;
        @media ${screen.xLarge} {
          font-size: 1.1rem;
          margin-bottom: 20px;
        }
      }
    }
    &__list-title {
      color: ${mainWhite};
      margin-bottom: 0;
      margin-left: 0;
      font-style: italic;
      font-size: 1.15rem;
      font-weight: 600;
      margin-top: 30px;
      @media ${screen.small} {
        margin-top: 30px;
        font-size: 1.6rem;
        margin-bottom: 20px;
        margin-left: 0;
      }
      @media ${screen.medium} {
        margin-left: 20px;
        margin-top: 0;
      }
    }

    &__list-container {
      @media ${screen.small} {
        margin-left: 0;
      }
      @media ${screen.medium} {
        margin-left: 0;
        margin-left: 45px;
        max-width: 624px;
      }
      @media ${screen.xLarge} {
        margin-left: 0;
      }
    }

    &__list {
      margin-top: 22px;
      display: none;
      @media ${screen.small} {
        display: block;
      }
      li {
        display: flex;
        margin-bottom: 45px;
        @media ${screen.medium} {
          max-width: 624px;
        }
      }
      .icons {
        min-width: 75px;
        height: 75px;
        align-self: flex-start;
        margin: 0 30px;
        @media ${screen.small} {
          margin: 0 30px 0 0;
        }
        @media ${screen.medium} {
          margin: 0 30px;
        }
      }

      .title {
        color: ${darkBlue};
        margin-bottom: 5px;
        font-size: 1.1rem;
        font-weight: 900;
      }
      .content {
        color: ${mainWhite};
        font-size: 1rem;
        margin-bottom: 10px;
        @media ${screen.xLarge} {
          font-size: 1.1rem;
        }
      }
    }

    &__mobile-accordion {
      max-width: 674px;
      padding: 8px 0;
      margin-top: 15px;
      @media ${screen.small} {
        display: none;
      }

      li {
        &:last-child {
          p {
            margin-bottom: 0;
          }
        }
      }

      h4 {
        color: ${darkBlue};
        font-size: 1rem;
        font-weight: 700;
      }

      p {
        color: ${mainWhite};
        margin-top: 2px;
        margin-bottom: 12px;
      }
    }
  }

  .outcomes-container {
    padding-top: 45px;
    position: relative;
    @media ${screen.small} {
      padding-top: 60px;
    }
    @media ${screen.medium} {
      padding-top: 160px;
    }
    @media ${screen.large} {
      padding-top: 180px;
    }

    &__dashed-top {
      width: 135px;
      position: absolute;
      top: 0;
      display: none;
      @media ${screen.large} {
        display: block;
      }
      .dashed {
        stroke-dasharray: 12 16;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: ${movingDash} 7s ease-out forwards;
      }
    }

    &__head-txt {
      color: ${mainWhite};
      font-size: 1.35rem;
      text-align: center;
    }

    .inner-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .subtitle {
      color: ${darkBlue};
      margin-bottom: 2px;
      @media ${screen.small} {
        margin-bottom: 20px;
      }
    }

    &__img {
      max-width: 462px;
      width: 100%;
      display: none;
      @media ${screen.medium} {
        display: block;
        max-width: 320px;
        margin-left: 30px;
      }
      @media ${screen.medium} {
        max-width: 350px;
        margin-left: 0;
      }
      @media ${screen.large} {
        max-width: 462px;
      }
    }

    &__content {
      @media ${screen.medium} {
        max-width: 674px;
      }
      h4 {
        font-size: 1.15rem;
        color: ${mainWhite};
        margin-bottom: 4px;
        font-weight: 600;
        @media ${screen.small} {
          font-size: 1.6rem;
          margin-bottom: 20px;
        }
      }
      p {
        color: ${mainWhite};
        font-size: 1rem;
        margin-bottom: 20px;
        @media ${screen.xLarge} {
          font-size: 1.1rem;
        }
      }
      strong {
        color: ${lightBlue};
        display: block;
        margin-bottom: -15px;
      }

      ul {
        color: ${mainWhite};
        margin-left: 0;
        margin-bottom: 20px;
        padding: 8px 0 8px 15px;
        @media ${screen.small} {
          margin-left: 35px;
          padding: 0;
        }
        li {
          margin: 0 0 10px 0;
          font-size: 1rem;
          list-style-type: disc;
          @media ${screen.small} {
            margin: 6px 0;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .download-faqs-btn {
      margin-top: 26px;
      margin-bottom: 45px;
      width: 100%;
      text-align: center;
      @media ${screen.xSmall} {
        width: auto;
        margin-top: 44px;
      }
      @media ${screen.medium} {
        margin-bottom: 110px;
      }
      @media ${screen.large} {
        margin-bottom: 135px;
      }
    }
  }
`;
//********** END ONLINE  **********//

const Banner = styled.div`
  @media ${screen.xSmall} {
    overflow: hidden;
  }
  @media ${screen.small} {
    height: 410px;
  }
  @media ${screen.medium} {
    display: none;
  }
`;

const Programs = ({ data }) => {
  const productContent = data.product.edges;
  const titleTag = data.content.data.title_tag__programs_;
  const metaDescription =
    data.content.data.meta_description__programs_for_change_;
  const imgBanner = data.content.data.banner__small_screen_.gatsbyImageData;
  const mainDescription = data.content.data.main_description.html;
  const mainFeaturedImg = data.content.data.main_featured_image.gatsbyImageData;
  const badSignList = data.content.data.bad_practice_signs;
  const badSignDescription = data.content.data.bad_signs_description.html;
  const badSignFeaturedImg =
    data.content.data.bad_signs_featured_image.gatsbyImageData;
  const strategyDescriptionOnline =
    data.content.data.strategy_description1.html;
  const strategyListInformationOnline =
    data.content.data.strategy_list_information1;
  const strategyFeaturedImgOnline =
    data.content.data.strategy_featured_image1.gatsbyImageData;
  const featuresDescriptionOnline =
    data.content.data.features_description1.html;
  const featureListOnline = data.content.data.features_list1;
  const outcomesDescriptionOnline =
    data.content.data.outcomes_description1.html;
  const outcomesFeaturedImgOnline =
    data.content.data.outcomes_featured_image1.gatsbyImageData;
  const faqsOnlinePdf =
    data.details.data.faq_s_for_the_online_client_centric_advice_program.url;

  const [mainDashed1And2IsShowing, setMainDashed1And2IsShowing] =
    useState(false);
  const [mainDashed3IsShowing, setMainDashed3IsShowing] = useState(false);

  const [onlineDashed1IsShowing, setOnlineDashed1IsShowing] = useState(false);
  const [onlineDashed2IsShowing, setOnlineDashed2IsShowing] = useState(false);

  // Dashed lines start drawing once set to true. Functions will be called by Waypoint.
  const showMainDashed1And2 = () => {
    setMainDashed1And2IsShowing(true);
  };
  const showMainDashed3 = () => {
    setMainDashed3IsShowing(true);
  };

  const showOnlineDashed1 = () => {
    setOnlineDashed1IsShowing(true);
  };
  const showOnlineDashed2 = () => {
    setOnlineDashed2IsShowing(true);
  };

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Banner>
        <GatsbyImage image={imgBanner} alt="Global Adviser Alpha programs" />
      </Banner>
      <Wrapper>
        {/*********** START STICKY NAV ***********/}
        <div className="nav-bar">
          <Scrollspy
            className="nav-bar__list"
            items={[
              "top",
              "signs",
              "strategy",
              "features",
              "outcomes",
              "get-started",
              "success-stories",
            ]}
            currentClassName="is-current"
          >
            <AnchorLink className="nav-bar__links--hidden" href="#top">
              <li>
                <p>Hide</p>
              </li>
            </AnchorLink>
            <AnchorLink className="nav-bar__links" href="#signs">
              <li>
                <p>Signs</p>
                <SignsIcon />
              </li>
            </AnchorLink>
            <AnchorLink className="nav-bar__links" href="#strategy">
              <li>
                <p>Strategy</p>
                <StrategyIcon />
              </li>
            </AnchorLink>
            <AnchorLink className="nav-bar__links" href="#features">
              <li>
                <p>Features</p>
                <FeaturesIcon />
              </li>
            </AnchorLink>
            <AnchorLink className="nav-bar__links" href="#outcomes">
              <li>
                <p>Outcomes</p>
                <OutcomesIcon />
              </li>
            </AnchorLink>
            <AnchorLink className="nav-bar__links" href="#get-started">
              <li>
                <p>Pricing</p>
                <PricingIcon />
              </li>
            </AnchorLink>
            <AnchorLink className="nav-bar__links" href="#success-stories">
              <li>
                <p>Success stories</p>
                <SuccessStoriesIcon />
              </li>
            </AnchorLink>
          </Scrollspy>
        </div>
        {/*********** END STICKY NAV ***********/}
        <div id="top" className="main-description">
          <div className="inner-wrapper">
            <div className="main-description__content">
              {/* <PageTitle className="title">Our Program</PageTitle> */}
              <div className="vector-heading">
                <IconHeading />
              </div>
              <div dangerouslySetInnerHTML={{ __html: mainDescription }} />
            </div>
            <div className="main-description__img">
              <GatsbyImage
                image={mainFeaturedImg}
                alt="Global Adviser Alpha provides a coaching and mentoring program"
              />
            </div>
          </div>
        </div>
        <div id="signs" className="signs-container">
          <InnerWrapper className="inner-wrapper">
            <Subtitle className="subtitle" white>
              Some signs your practice isn't a business
            </Subtitle>
            <ul className="signs-container__list">
              {badSignList.map((sign) => {
                return (
                  <li key={sign.title1}>
                    <h3>{sign.title1}</h3>
                    <p>{sign.description}</p>
                  </li>
                );
              })}
            </ul>
            <div className="dashed-line-container">
              {mainDashed1And2IsShowing ? (
                <MainDashed1 className="dashed-line-container__top-line" />
              ) : null}
              <Waypoint onEnter={showMainDashed1And2} topOffset="200px">
                <h3>But there is a global best practice proven better way!</h3>
              </Waypoint>
              {mainDashed1And2IsShowing ? (
                <MainDashed2 className="dashed-line-container__left-line" />
              ) : null}
            </div>
          </InnerWrapper>

          <div className="secondary-description">
            <InnerWrapper className="inner-wrapper">
              <div>
                <div
                  className="secondary-description__content"
                  dangerouslySetInnerHTML={{ __html: badSignDescription }}
                />
              </div>

              <div className="secondary-description__img">
                <GatsbyImage
                  image={badSignFeaturedImg}
                  alt="Start to see growth and increased profits"
                />
              </div>
            </InnerWrapper>
            {mainDashed3IsShowing ? (
              <MainDashed3 className="secondary-description__dashed-bottom" />
            ) : null}
          </div>
        </div>

        <Waypoint onEnter={showMainDashed3} topOffset="200px">
          <OnlineWrapper>
            <div id="strategy" className="strategy-container">
              <PageTitle className="main-title">
                <span className="main-title__top">
                  Make the change <span className="break">and join our</span>
                </span>
                Client-Centric Advice Program!
                <span className="main-title__bottom">
                  Online or Facilitated
                </span>
              </PageTitle>

              <InnerWrapper className="inner-wrapper">
                <div>
                  <Subtitle className="subtitle" white>
                    Strategy
                  </Subtitle>
                  <Waypoint onEnter={showOnlineDashed1} bottomOffset="1100px">
                    <div
                      className="strategy-container__description"
                      dangerouslySetInnerHTML={{
                        __html: strategyDescriptionOnline,
                      }}
                    />
                  </Waypoint>
                  <ul className="strategy-container__list">
                    {strategyListInformationOnline.map((list, i) => (
                      <li key={i}>
                        <h4>{list.title1}</h4>
                        <p>{list.description}</p>
                      </li>
                    ))}
                  </ul>
                  <ul className="strategy-container__mobile-accordion">
                    {strategyListInformationOnline.map((list, i) => (
                      <li key={i}>
                        <h4>{list.title1}</h4>
                        <p>{list.description}</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="strategy-container__img">
                  <GatsbyImage
                    image={strategyFeaturedImgOnline}
                    alt="Global Adviser Alpha Strategy"
                  />
                </div>
              </InnerWrapper>
            </div>
            <div id="features" className="features-container">
              <InnerWrapper>
                {onlineDashed1IsShowing ? (
                  <OnlineDashed1 className="features-container__dashed-top" />
                ) : null}
                <Subtitle className="subtitle" white>
                  Features
                </Subtitle>
                <div className="features-container__subcontainer">
                  <div
                    className="features-container__content"
                    dangerouslySetInnerHTML={{
                      __html: featuresDescriptionOnline,
                    }}
                  />
                  <div className="features-container__list-container">
                    <h4 className="features-container__list-title">
                      The program shows you how to:
                    </h4>
                    <ul className="features-container__list">
                      {featureListOnline.map((list) => {
                        return (
                          <li key={list.title1}>
                            <img
                              className="icons"
                              src={list.icon.url}
                              alt={list.title1}
                            />
                            <div className="text-container">
                              <h4 className="title">{list.title1}</h4>
                              <p className="content">{list.description}</p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <ul className="features-container__mobile-accordion">
                      {featureListOnline.map((list, i) => (
                        <li key={i}>
                          <h4>{list.title1}</h4>
                          <p className="content">{list.description}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </InnerWrapper>
            </div>
            <div id="outcomes" className="outcomes-container">
              <InnerWrapper className="inner-wrapper">
                {onlineDashed2IsShowing ? (
                  <OnlineDashed2 className="outcomes-container__dashed-top" />
                ) : null}
                <div>
                  <Waypoint onEnter={showOnlineDashed2} topOffset="150px">
                    <Subtitle className="subtitle" white>
                      Outcomes
                    </Subtitle>
                  </Waypoint>
                  <div
                    className="outcomes-container__content"
                    dangerouslySetInnerHTML={{
                      __html: outcomesDescriptionOnline,
                    }}
                  />
                  <StyledA
                    className="download-faqs-btn"
                    href={faqsOnlinePdf}
                    target="_blank"
                    download
                    updown
                    whiteHover
                    normal
                  >
                    DOWNLOAD FAQ'S <FaDownload />
                  </StyledA>
                </div>
                <div className="outcomes-container__img">
                  <GatsbyImage
                    image={outcomesFeaturedImgOnline}
                    alt="Global Adviser Alpha Outcomes"
                  />
                </div>
              </InnerWrapper>
            </div>
          </OnlineWrapper>
        </Waypoint>
        <div className="get-started-container">
          <CountSection />
          <Products productContent={productContent} />
        </div>

        <div id="success-stories" className="success-stories-container">
          <Carousel testimonials={data.testimonials.edges} service />
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Programs;

export const data = graphql`
  {
    content: prismicServices {
      data {
        title_tag__programs_
        meta_description__programs_
        main_description {
          html
        }
        banner__small_screen_ {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        main_featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        bad_practice_signs {
          title1
          description
        }
        bad_signs_description {
          html
        }
        bad_signs_featured_image {
          alt

          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        strategy_description {
          html
        }
        strategy_list_information {
          title1
          description
        }
        strategy_list_information1 {
          title1
          description
        }
        strategy_description1 {
          html
        }
        strategy_featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        strategy_featured_image1 {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        outcomes_description {
          html
        }
        outcomes_description1 {
          html
        }
        outcomes_featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        outcomes_featured_image1 {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        features_description {
          html
        }
        features_description1 {
          html
        }
        features_list {
          icon {
            url
          }
          title1
          description {
            html
          }
        }
        features_list1 {
          icon {
            url
          }
          title1
          description
        }
      }
    }
    testimonials: allPrismicTestimonial(
      filter: { data: { is_program_display_page: { eq: true } } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          id
          data {
            full_name {
              text
            }
            company {
              text
            }
            occupation_title {
              text
            }
            photo {
              url
            }
            message {
              text
            }
          }
        }
      }
    }
    details: prismicGeneralDetails {
      data {
        program_fees {
          url
        }
        email_address
        faq_s_for_the_online_client_centric_advice_program {
          url
        }
        faq_s_for_the_face_to_face_business_transformation_program {
          url
        }
      }
    }
    product: allShopifyProduct {
      edges {
        node {
          tags
          id
          title
          variants {
            shopifyId
            price
          }
        }
      }
    }
  }
`;
