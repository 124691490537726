import React, { useContext } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import {
  Button,
  Subtitle,
  lightGrey,
  mainWhite,
  lightBlue,
  darkBlue,
  screen,
} from "../common/variables";
// import { FaChevronRight } from "react-icons/fa";
import { GoDash } from "react-icons/go";
import { StoreContext } from "../../context";
import { IoIosCheckmark } from "react-icons/io";
import Loader from "../assets/icons/puff.svg";

const Wrapper = styled.div`
  border: 1px solid #ccc;
  background: #fff;
  color: ${darkBlue};
  text-align: center;
  padding: 42px 35px;
  @media ${screen.medium} {
    padding: 100px 60px;
  }
  @media ${screen.xLarge} {
    padding: 100px 100px;
  }

  @media ${screen.xXLarge} {
    padding: 100px 140px;
  }

  .table-heading {
    margin-bottom: 4px;
    @media ${screen.small} {
      margin-bottom: 20px;
    }
  }

  .table-description {
    font-size: 1.15rem;
    font-weight: 600;
    @media ${screen.small} {
      font-size: 1.6rem;
    }

    .break {
      display: block;
      @media ${screen.small} {
        display: inline;
      }
    }
  }

  #table-lg-screen {
    display: none;
    @media ${screen.small} {
      display: block;
    }

    .table {
      margin: 50px 0 0 0;

      &__headings {
        display: flex;

        .column {
          width: 33.33%;
          border: 1px solid #d3d3d3;
          padding: 50px 10px;
          background: ${lightGrey};
          @media ${screen.small} {
            padding: 32px 10px;
          }
          @media ${screen.large} {
            padding: 36px 10px;
          }
          @media ${screen.xXLarge} {
            padding: 50px 10px;
          }

          span {
            font-size: 1.15rem;
            font-weight: 900;
            display: block;
            @media ${screen.small} {
              font-size: 1.35rem;
            }
            @media ${screen.medium} {
              font-size: 1.4rem;
            }
            @media ${screen.large} {
              font-size: 1.55rem;
            }
            @media ${screen.xXLarge} {
              font-size: 1.65rem;
            }
          }
        }
      }

      &__rows {
        display: flex;
        flex-direction: column;
        @media ${screen.small} {
          flex-direction: row;
        }

        .column {
          width: 100%;
          border: 1px solid #d3d3d3;
          padding: 36px 10px;
          position: relative;
          @media ${screen.small} {
            width: 33.33%;
            padding: 28px 10px;
          }
          @media ${screen.large} {
            padding: 30px 10px;
          }
          @media ${screen.xXLarge} {
            padding: 34px 10px;
          }

          span {
            font-size: 1rem;
            font-weight: 400;
            display: block;
            max-width: 370px;
            margin: 0 auto;
            line-height: 1.35;
            @media ${screen.small} {
              font-size: 1.1rem;
            }
            @media ${screen.medium} {
              font-size: 1.2rem;
            }
            @media ${screen.large} {
              font-size: 1.35rem;
            }
            @media ${screen.xXLarge} {
              font-size: 1.5rem;
            }
          }

          svg {
            font-size: 4.3rem;
            line-height: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media ${screen.large} {
              font-size: 5.1rem;
            }
          }

          &--1 {
            background: ${lightGrey};

            span {
              font-weight: 900;
            }
          }

          &--empty {
            span {
              svg {
                color: #d3d3d3;
                font-size: 2.5rem;
              }
            }
          }

          &--blue {
            background: ${lightBlue};
            position: relative;

            .loader {
              position: absolute;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.95);
              z-index: 99;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              svg {
                width: 80px;
                height: 80px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }

        &--product {
          .column {
            color: ${mainWhite};
            padding: 60px 10px;
            p {
              line-height: 1.1;
            }
            &__heading {
              font-size: 1.05rem;
              font-weight: 400;
              @media ${screen.small} {
                font-size: 1.35rem;
              }
              @media ${screen.large} {
                font-size: 1.95rem;
              }
            }

            &__price {
              margin: 13px 0 11px 0;

              .currency,
              .digit {
                display: inline;
                font-weight: 300;
                font-size: 1.18rem;
                @media ${screen.small} {
                  font-size: 1.88rem;
                }
                @media ${screen.large} {
                  font-size: 1.68rem;
                  font-size: 2.3rem;
                }
              }

              .digit {
                font-weight: 700;
                margin: 0 0 0 10px;
              }
            }

            &__tax {
              font-weight: 300;
              font-size: 1.05rem;

              span {
                font-weight: 300;
                font-size: 0.8rem;
                display: block;
                margin: 5px auto 0 auto;
              }
            }

            button {
              display: block;
              margin: 35px auto 11px auto;
              position: relative;
              &:hover {
                background: ${mainWhite};
                color: ${lightBlue};
              }
            }
          }
        }
      }
    }
  }

  #table-sm-screen {
    @media ${screen.small} {
      display: none;
    }

    .table {
      border-top: 1px solid #d3d3d3;
      margin: 40px 0;

      .row {
        border-left: 1px solid #d3d3d3;
        border-right: 1px solid #d3d3d3;
        border-bottom: 1px solid #d3d3d3;
        padding: 18px 20px;
        span {
          display: block;
          font-size: 1rem;
        }

        &--heading {
          background: #ededed;
          padding: 29px 20px;

          span {
            strong {
              display: block;
              font-size: 1.56rem;
              font-weight: 900;
              margin: 8px 0 0 0;
            }
          }
        }

        &--product {
          background: ${lightBlue};
          position: relative;

          .loader {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.95);
            z-index: 99;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            svg {
              width: 57px;
              height: 57px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .column {
            color: ${mainWhite};
            padding: 35px 0;

            p {
              line-height: 1.1;
            }

            .product-heading {
              font-size: 1.6rem;
              font-weight: 400;
            }

            .product-price {
              margin: 13px 0 11px 0;

              .currency,
              .digit {
                display: inline;
                font-weight: 300;
                font-size: 2.2rem;
              }

              .digit {
                font-weight: 700;
                margin: 0 0 0 7px;
              }
            }

            .product-tax {
              font-weight: 300;
              font-size: 0.9rem;

              span {
                display: block;
                margin: 5px 0 0 0;
                font-weight: 300;
                font-size: 0.76rem;
              }

              &--sm {
                font-size: 0.74rem;
              }
            }

            button {
              display: block;
              width: 100%;
              margin: 35px auto 11px auto;
              &:hover {
                background: ${mainWhite};
                color: ${lightBlue};
              }
            }
          }
        }
      }
    }
  }
`;

const ProductTable = ({ productContent }) => {
  const { goToCheckout, isLoading, isAustralia } = useContext(StoreContext);

  // get particular product by id
  // no gst product
  const noGSTOnlineProduct = productContent.filter((item) => {
    return (
      item.node.variants[0].shopifyId ===
      "gid://shopify/ProductVariant/32109339672627"
    );
  })[0].node;

  // get particular product by id
  // with gst product
  const withGSTOnlineProduct = productContent.filter((item) => {
    return (
      item.node.variants[0].shopifyId ===
      "gid://shopify/ProductVariant/32109317685299"
    );
  })[0].node;

  const noGSTFacilitated = productContent.filter((item) => {
    return (
      item.node.variants[0].shopifyId ===
      "gid://shopify/ProductVariant/32109735084083"
    );
  })[0].node;

  const withGSTFacilitated = productContent.filter((item) => {
    return (
      item.node.variants[0].shopifyId ===
      "gid://shopify/ProductVariant/32109355597875"
    );
  })[0].node;

  const onlineProductVariantId = isAustralia
    ? withGSTOnlineProduct.variants[0].shopifyId
    : noGSTOnlineProduct.variants[0].shopifyId;

  const onlinePrice = Number(withGSTOnlineProduct.variants[0].price);

  const facilitatedPrice = Number(withGSTFacilitated.variants[0].price);

  const isOnlineEarlybird =
    withGSTOnlineProduct.tags.some((item) => item === "early bird") ||
    noGSTOnlineProduct.tags.some((item) => item === "early bird");

  const isFacilitatedEarlybird =
    withGSTFacilitated.tags.some((item) => item === "early bird") ||
    noGSTFacilitated.tags.some((item) => item === "early bird");

  return (
    <Wrapper>
      <Subtitle className="table-heading" dark={true}>
        Compare
      </Subtitle>
      <p className="table-description">
        <em>
          Which learning system will work{" "}
          <span className="break">best for you?</span>
        </em>
      </p>

      {/* SMALL SCREEN TABLE */}
      <div id="table-sm-screen">
        <div className="table">
          <div className="row row--heading">
            <span>
              Client-Centric Advice Program <strong>Online</strong>
            </span>
          </div>
          <div className="row">
            <span>Global best practice tools</span>
          </div>
          <div className="row">
            <span>5 chapters - 27 modules</span>
          </div>
          <div className="row">
            <span>Over 30 videos (>4 hours)</span>
          </div>
          <div className="row">
            <span>38 scripts/templates/agendas</span>
          </div>
          <div className="row">
            <span>Facilitated online portal</span>
          </div>
          <div className="row">
            <span>
              Facilitated group Q&A, collaboration & problem solving via social
              media
            </span>
          </div>
          <div className="row">
            <span>Self paced (3-4 months suggested)</span>
          </div>
          <div className="row row--product">
            {isLoading && (
              <div className="loader">
                <Loader />
              </div>
            )}
            <div className="column">
              {isOnlineEarlybird && (
                <p className="product-heading">Early bird price!*</p>
              )}

              <p className="product-price">
                <span className="currency">AUD</span>
                <span className="digit">${onlinePrice}</span>
              </p>
              {isAustralia && (
                <p className="product-tax product-tax--sm">
                  (GST not included)
                </p>
              )}
              <Button
                dark={true}
                onClick={() => goToCheckout(onlineProductVariantId)}
              >
                JOIN NOW
              </Button>
              {isOnlineEarlybird && (
                <p className="product-tax">
                  Regular price AUD $2950 {isAustralia && "(ex GST)"}
                  <span>*Limited to the first 20 registered</span>
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="table">
          <div className="row row--heading">
            <span>
              Client-Centric Advice Program <strong>Enterprise</strong>
            </span>
          </div>
          <div className="row">
            <span>Global best practice tools</span>
          </div>
          <div className="row">
            <span>5 chapters - 27 modules</span>
          </div>
          <div className="row">
            <span>Over 30 videos ({">"}4 hours)</span>
          </div>
          <div className="row">
            <span>38 scripts/templates/agendas</span>
          </div>
          <div className="row">
            <span>
              Facilitated online portal
              <br />
              Plus monthly individual call
              <br />
              Plus monthly group call
            </span>
          </div>
          <div className="row">
            <span>
              Facilitated group Q&A, collaboration & problem solving via social
              media
              <br />
              Plus calls as required outside check in calls
            </span>
          </div>
          <div className="row">
            <span>Numbers limited (Min 12–Max 20)</span>
          </div>
          <div className="row">
            <span>1/2 day x 4 Zoom meetings</span>
          </div>
          <div className="row">
            <span>6 months</span>
          </div>
          <div className="row">
            <span>Personal coaching by David</span>
          </div>
          <div className="row row--product">
            <div className="column">
              {isFacilitatedEarlybird && (
                <p className="product-heading">Early bird price!*</p>
              )}

              <p className="product-price">
                <span className="currency">AUD</span>
                <span className="digit">${facilitatedPrice}</span>
              </p>
              {isAustralia && <p className="product-tax">(GST not included)</p>}
              <Button
                dark={true}
                onClick={() =>
                  navigate("/client-centric-advice-program-registration/")
                }
              >
                REGISTER NOW
              </Button>
              {isFacilitatedEarlybird && (
                <p className="product-tax">
                  Regular price AUD $5450 {isAustralia && "(ex GST)"}
                  <span>*Limited to the first 20 registered</span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* // END SMALL SCREEN TABLE */}

      <div id="table-lg-screen">
        <div className="table">
          <div className="table__headings">
            <div className="column column--1"></div>
            <div className="column">
              <span>Online</span>
            </div>
            <div className="column">
              <span>Enterprise</span>
            </div>
          </div>
          <div className="table__rows">
            <div className="column column--1">
              <span>Global best practice tools</span>
            </div>
            <div className="column">
              <span>
                <IoIosCheckmark />
              </span>
            </div>
            <div className="column">
              <span>
                <IoIosCheckmark />
              </span>
            </div>
          </div>
          <div className="table__rows">
            <div className="column column--1">
              <span>Chapters/modules</span>
            </div>
            <div className="column">
              <span>5 chapters - 27 modules</span>
            </div>
            <div className="column">
              <span>5 chapters - 27 modules</span>
            </div>
          </div>
          <div className="table__rows">
            <div className="column column--1">
              <span>Videos</span>
            </div>
            <div className="column">
              <span>Over 30 videos (>4 hours)</span>
            </div>
            <div className="column">
              <span>Over 30 videos (>4 hours)</span>
            </div>
          </div>
          <div className="table__rows">
            <div className="column column--1">
              <span>Scripts/Templates/Agendas</span>
            </div>
            <div className="column">
              <span>38</span>
            </div>
            <div className="column">
              <span>38</span>
            </div>
          </div>
          <div className="table__rows">
            <div className="column column--1">
              <span>Check-in-calls</span>
            </div>
            <div className="column">
              <span>Facilitated online portal</span>
            </div>
            <div className="column">
              <span>
                Facilitated online portal <br />
                Plus monthly individual call <br />
                Plus monthly group call
              </span>
            </div>
          </div>
          <div className="table__rows">
            <div className="column column--1">
              <span>24/7 access</span>
            </div>
            <div className="column">
              <span>Online group & facilitated portal</span>
            </div>
            <div className="column">
              <span>
                Online group & facilitated portal
                <br />
                Plus calls as required outside <br />
                check in calls
              </span>
            </div>
          </div>
          <div className="table__rows">
            <div className="column column--1">
              <span>Limited numbers</span>
            </div>
            <div className="column column--empty">
              <span>
                <GoDash />
              </span>
            </div>
            <div className="column">
              <span>Min 12 – Max 20</span>
            </div>
          </div>
          <div className="table__rows">
            <div className="column column--1">
              <span>Face to face contact</span>
            </div>
            <div className="column column--empty">
              <span>
                <GoDash />
              </span>
            </div>
            <div className="column">
              <span>4 Zoom meetings (3 hours)</span>
            </div>
          </div>
          <div className="table__rows">
            <div className="column column--1">
              <span>Time</span>
            </div>
            <div className="column">
              <span>Self paced (3-4 months suggested)</span>
            </div>
            <div className="column">
              <span>6 months</span>
            </div>
          </div>
          <div className="table__rows">
            <div className="column column--1">
              <span>Personal coaching</span>
            </div>
            <div className="column column--empty">
              <span>
                <GoDash />
              </span>
            </div>
            <div className="column ">
              <span>
                <IoIosCheckmark />
              </span>
            </div>
          </div>
          <div className="table__rows table__rows--product">
            <div className="column column--1"></div>
            <div className="column column--blue">
              {isLoading && (
                <div className="loader">
                  <Loader />
                </div>
              )}
              {isOnlineEarlybird && (
                <p className="column__heading">Early bird price!*</p>
              )}

              <p className="column__price">
                <span className="currency">AUD</span>
                <span className="digit">${onlinePrice}</span>
              </p>

              {isAustralia && <p className="column__tax">(GST not included)</p>}

              <Button
                dark={true}
                onClick={() => goToCheckout(onlineProductVariantId)}
              >
                JOIN NOW
              </Button>
              {isOnlineEarlybird && (
                <p className="column__tax">
                  Regular price AUD $2950 {isAustralia && "(ex GST)"}
                  <span>*Limited to the first 20 registered</span>
                </p>
              )}
            </div>
            <div className="column column--blue">
              {isFacilitatedEarlybird && (
                <p className="column__heading">Early bird price!*</p>
              )}

              <p className="column__price">
                <span className="currency">AUD</span>
                <span className="digit">${facilitatedPrice}</span>
              </p>
              {isAustralia && <p className="column__tax">(GST not included)</p>}
              <Button
                dark={true}
                onClick={() =>
                  navigate("/client-centric-advice-program-registration/")
                }
              >
                REGISTER NOW
              </Button>
              {isFacilitatedEarlybird && (
                <p className="column__tax">
                  Regular price AUD $5450 {isAustralia && "(ex GST)"}
                  <span>*Limited to the first 20 registered</span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProductTable;
