import React from "react";
import styled from "styled-components";
import { screen, mainWhite, darkBlue, InnerWrapper } from "../common/variables";

const Wrapper = styled.div`
  background: ${darkBlue};
  padding: 0 0 68px 0;

  .count-list {
    color: ${mainWhite};
    display: flex;
    flex-direction: column;
    @media ${screen.small} {
      flex-direction: row;
    }

    li {
      text-align: center;
      width: 100%;
      margin: 0 0 56px 0;
      @media ${screen.small} {
        width: 33.33%;
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }

      h6 {
        font-size: 1.25rem;
        font-variant-numeric: tabular-nums;
        font-weight: 400;
        margin: 0 0 8px 0;
        @media ${screen.small} {
          margin: 0 0 20px 0;
        }
        @media ${screen.medium} {
          font-size: 1.15rem;
        }
      }

      p {
        font-size: 3.3rem;
        font-weight: 700;
        line-height: 1.05;
        @media ${screen.small} {
          font-size: 2.5rem;
        }
        @media ${screen.medium} {
          font-size: 1.9rem;
        }
        @media ${screen.large} {
          font-size: 2.5rem;
        }
      }
    }
  }
`;

const CountSection = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <ul className="count-list">
          <li>
            <h6>33 years experience</h6>
            <p>1 program</p>
          </li>

          <li>
            <h6>19 countries worked in</h6>
            <p>1 portal</p>
          </li>

          <li>
            <h6>Worked with {">"}20,000 advisers</h6>
            <p id="get-started">
              Global best
              <br /> practice
            </p>
          </li>
        </ul>
      </InnerWrapper>
    </Wrapper>
  );
};

export default CountSection;
